import {type ReactNode, useCallback} from 'react';
import {StudentSideNav} from 'modules/Students/StudentSideNav';
import {StudentUserMenu} from 'modules/Students/StudentUserMenu';
import {StudentBottomNav} from 'modules/Students/StudentBottomNav';
import {useRouter} from '@halp/ui/router';
import {Shell, SidebarNav, useBreakpoint} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {useUser} from '@halp/foundation/Users';
import {imgLogo} from '@halp/images';
import {StudentBanner} from '../StudentBanner';

const ACCOUNT_PATHS = [
	Paths.account.application.url(),
	Paths.account.settings.url(),
	Paths.account.profile.url(),
];

export function AppShell({children}: {children: ReactNode}) {
	const {user} = useUser();
	const router = useRouter();
	// todo: @jtsmills look at this
	const mobile = useBreakpoint({max: 'md'});

	const hideTopSideNav =
		router.asPath === Paths.onboarding.url() ||
		user == null ||
		(mobile && ACCOUNT_PATHS.includes(router.asPath));

	const sideNav = useCallback(
		(_mobile: boolean, setOpenMenu: (open: boolean) => void) => {
			if (user?.studentProfile != null) {
				return <StudentSideNav setOpenMenu={setOpenMenu} />;
			}

			return <SidebarNav logo={imgLogo} items={[]} />;
		},
		[user?.studentProfile],
	);

	const bottomNav = useCallback((_mobile: boolean) => {
		return <StudentBottomNav />;
	}, []);

	return (
		<Shell
			flexNav
			bottomNav={mobile && user ? bottomNav : undefined}
			sideNav={mobile ? undefined : sideNav}
			hideTopSideNav={hideTopSideNav}
			userMenu={<StudentUserMenu showCoach={mobile} />}
			alignment="column"
			banner={<StudentBanner />}
		>
			{children}
		</Shell>
	);
}
