import {useRouter} from 'next/router';
import {Stack, Heading, Link} from '@halp/ui';
import {useI18n} from '@halp/foundation/i18n';
import {Paths} from '@halp/foundation/Paths';

export function ArchivedBanner() {
	const {t} = useI18n();
	const router = useRouter();

	return (
		<Link
			onClick={() =>
				router.push(Paths.student.survey.archivedUnresponsive.url())
			}
		>
			<Stack direction="column">
				<Heading type="h5" color="white" align="center">
					{t('coach_messages.banner.archived.account_paused')}
				</Heading>
				<Heading type="h5" color="white" align="center">
					{t('coach_messages.banner.archived.improve_services')}
				</Heading>
			</Stack>
		</Link>
	);
}
