import {faTimes} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {Icon} from '../Icon';
import {Link} from '../Link';
import style from './Banner.module.css';
import type {ReactNode} from 'react';

export interface Props {
	children: ReactNode;
	color?: 'red' | 'blurple';
	onHide?: VoidFunction;
	className?: string;
}

export function Banner({
	children,
	color = 'blurple',
	onHide,
	className,
}: Props) {
	const closeButton = onHide ? (
		<div className={style.CloseButtonContainer}>
			<Link onClick={onHide}>
				<Icon icon={faTimes} />
			</Link>
		</div>
	) : null;

	const classes = classNames(
		style.Banner,
		CSSVariants(style, 'BannerColor', color),
		className,
	);

	return (
		<div className={classes}>
			<div className={style.BannerContent}>{children}</div>
			{closeButton}
		</div>
	);
}
